html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  background-color: #fff;
}

body {
  background-color: #fff;
  color: #3c4858;
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  line-height: 1.5em;
}

/* style sheet for "letter" printing */
@media print and (width: 8.5in) and (height: 13in) {
  @page {
    margin: 1in;
  }
}

#root {
  background-color: #fff;
}

blockquote footer:before,
blockquote small:before {
  content: "\2014 \00A0";
}

small {
  font-size: 80%;
}

h1 {
  font-size: 3em;
  line-height: 1.15em;
}

h2 {
  font-size: 2.4em;
}

h3 {
  font-size: 1.825em;
  line-height: 1.4em;
  margin: 20px 0 10px;
}

h4 {
  font-size: 1.3em;
  line-height: 1.4em;
}

h5 {
  font-size: 1.25em;
  line-height: 1.4em;
  margin-bottom: 15px;
}

h6 {
  font-size: 1em;
  text-transform: uppercase;
  font-weight: 500;
}

body {
  background-color: #eeeeee;
  color: #3c4858;
}

blockquote p {
  font-style: italic;
}

body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  line-height: 1.5em;
}

body,
.MuiTypography-h1,
.MuiTypography-body1,
.MuiTypography-h2,
.MuiTypography-h3,
.MuiTypography-h4,
.MuiTypography-h5,
.MuiTypography-h6 {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  line-height: 1.5em;
}

a {
  color: #9c27b0;
  text-decoration: none;
}

a:hover,
a:focus {
  color: #89229b;
  text-decoration: none;
}

legend {
  border-bottom: 0;
}

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
  letter-spacing: normal !important;
  box-sizing: border-box;
}

.flex {
  display: flex;
}

.justifyContent-Center {
  justify-content: center;
}

.justifyContent-Left {
  justify-content: flex-start;
}

.justifyContent-Right {
  justify-content: flex-end;
}

.justifyContent-SpaceAround {
  justify-content: space-around;
}

.justifyContent-SpaceBetween {
  justify-content: space-between;
}

.alignItem-Center {
  align-items: center;
}

.color-success {
  color: #4caf50;
}

.textAlign-left {
  text-align: left;
}

.textAlign-center {
  text-align: center;
}

.textAlign-right {
  text-align: right;
}

.margin-px0 {
  margin: 0;
}

.margin-px0-important {
  margin: 0 !important;
}

.fullHeight {
  height: 100%;
}
/* Scroll Bar CSS */
::-webkit-scrollbar {
  /* display: none; */
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #3f51b5;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3f51b5;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #3f51b5;
}

.buttonSuccess {
  background-color: #38c172 !important;
}

.buttonSuccess:hover {
  background-color: #38c172 !important;
}

.buttonReject {
  background-color: #e3342f !important;
}

.buttonReject:hover {
  background-color: #e3342f !important;
}

.photo {
  width: 250px !important;
  height: 250px !important;
  margin: 0 auto;
}
.photoBox {
  text-align: center;
}
.inputFile {
  display: none;
}

.cleared {
  background-color: #38c172 !important;
  color: #fff !important;
  padding: 6px 12px !important;
}

.not-cleared {
  background-color: #e3342f !important;
  color: #fff !important;
  padding: 6px 12px !important;
}

.grade-ok {
  background-color: #38c172 !important;
  color: #fff !important;
  padding: 6px 12px !important;
}

.grade-not-ok {
  background-color: #e3342f !important;
  color: #fff !important;
  padding: 6px 12px !important;
}

.bold {
  font-weight: bold !important;
}
